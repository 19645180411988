import React, { useEffect, useRef, useState } from "react";
import "./home.css";
import repairmen from "../../assets/coverimage.png";
import apjlogo from "../../assets/APJlogo.png";
import doorstep from "../../assets/doorstep.jpg";
import hardware from "../../assets/hardwareservice.png";
import displaysales from "../../assets/displayandsales.jpg";
import whatsapplogo from "../../assets/whatsapplogo.png";
import call from "../../assets/call.png";
import email from "../../assets/email.png";
import repairmachine from "../../assets/repairmachine.jpg";
import contactus from "../../assets/contactus.png";
import { GiLoveSong, GiTeamIdea } from "react-icons/gi";
import { FaHome, FaRupeeSign } from "react-icons/fa";
import { IoMdConstruct } from "react-icons/io";
import threetwoinch from "../../assets/32 inch.JPEG";
import fourzeroinch from "../../assets/40 inch.JPEG";
import fourthreeinch from "../../assets/43 inch.JPEG";
import fivefiveinch from "../../assets/55 inch.JPEG";
import aboutus from "../../assets/About us .jpg";
import "./header.css";

const Home = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const text =
    "User-Focused, Value-Based TV Repairs – Reliable, Efficient, Affordable. Genuine Parts, Quick Service, Hassle-Free Experience!";

  const faqData = [
    {
      question: "What types of TVs do you repair?",
      answer:
        "We repair all types of TVs, including LED, OLED, Smart TVs, Plasma TVs, and more, from leading brands like Samsung, LG, Sony, TCL, Panasonic, and MI.",
    },
    {
      question: "Do you provide in-home repair services?",
      answer:
        "Yes, we offer in-home repair services to save you the hassle of transporting your TV. For more complex issues, we also provide a convenient pick-up and drop-off service.",
    },
    {
      question: "How long does a TV repair typically take?",
      answer:
        "Most TV repairs are completed within 1-2 days. However, the exact time depends on the nature of the issue and the availability of parts.",
    },
    {
      question: "Is there a warranty on repairs?",
      answer:
        "Yes, all our repairs are backed by a warranty. The duration of the warranty depends on the type of repair or replacement part used.",
    },
    {
      question: "What should I do if my TV is not turning on?",
      answer:
        "If your TV isn’t powering on, check the power cord and outlet first. If the issue persists, contact us for a professional diagnosis.",
    },
  ];

  const reviews = [
    {
      id: 1,
      name: "Arun Kumar",
      review:
        "I am extremely impressed with the level of professionalism and dedication shown by the team. They quickly identified the issue with my TV and resolved it in no time. The service was efficient, and the staff was very courteous throughout. Highly recommended!",
    },
    {
      id: 2,
      name: "Priya Shankar",
      review:
        "The technicians were extremely knowledgeable and friendly. They explained the entire process and made sure I was comfortable with the solution. My TV is working like brand new now. Excellent work!",
    },
    {
      id: 3,
      name: "Ramesh Babu",
      review:
        "Affordable pricing and top-notch service! I called them to fix my Smart TV, and they arrived on time, well-prepared. They were thorough in their work and even gave me tips on how to maintain the TV better. Will definitely use their service again.",
    },
    {
      id: 4,
      name: "Lakshmi Narayanan",
      review:
        "Their commitment to customer satisfaction is truly remarkable. I was skeptical at first, but they went above and beyond to fix a complicated issue with my LED TV. The team stayed until late to ensure everything was perfect. Very reliable!",
    },
    {
      id: 5,
      name: "Meena Karthik",
      review:
        "Amazing service! From scheduling the appointment to completing the repair, everything was seamless. The staff was polite and professional. My TV’s sound issue was resolved quickly, and the quality is now better than ever.",
    },
    {
      id: 6,
      name: "Karthik Subramaniam",
      review:
        "The team was punctual, friendly, and highly skilled. They fixed a rare issue with my Smart TV that others had failed to resolve. Their pricing is very reasonable for the quality of work they deliver. Highly satisfied!",
    },
    {
      id: 7,
      name: "Divya Srinivasan",
      review:
        "I am incredibly thankful for the prompt and excellent service. My TV had a display issue that I thought couldn’t be repaired, but they managed to fix it perfectly. They even offered a warranty on their work. Wonderful experience!",
    },
    {
      id: 8,
      name: "Suresh Balakrishnan",
      review:
        "Fantastic service! The technicians were well-equipped and quickly diagnosed the issue. They explained the problem in simple terms and fixed my TV in a couple of hours. I will recommend their service to all my friends and family.",
    },
    {
      id: 9,
      name: "Anitha Mohan",
      review:
        "Professional and efficient! I had a great experience with their service. My TV was not turning on, and they managed to repair it on the same day. The customer support team was very responsive and helpful. Thank you so much!",
    },
    {
      id: 10,
      name: "Vijay Raghavan",
      review:
        "Exceptional service! The technicians displayed incredible expertise and patience while fixing my 4K TV. They even tested it multiple times to ensure everything was functioning perfectly. I am extremely satisfied with the entire experience.",
    },
  ];

  const scrollRef = useRef(null);

  useEffect(() => {
    const scrollInterval = setInterval(() => {
      if (scrollRef.current) {
        scrollRef.current.scrollBy({
          top: 0,
          left: 300,
          behavior: "smooth",
        });

        if (
          scrollRef.current.scrollLeft + scrollRef.current.offsetWidth >=
          scrollRef.current.scrollWidth
        ) {
          scrollRef.current.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }
      }
    }, 3000);

    return () => clearInterval(scrollInterval);
  }, []);

  const features = [
    {
      id: 1,
      icon: <GiTeamIdea size={40} color="#0078ff" />,
      title: "Expert Team",
      description:
        "Our expert technicians are certified and highly experienced to handle all your TV repair needs.",
    },
    {
      id: 2,
      icon: <FaHome size={40} color="#0078ff" />,
      title: "Doorstep Service",
      description:
        "We bring our services to your home for convenience and ease of access.",
    },
    {
      id: 3,
      icon: <IoMdConstruct size={40} color="#0078ff" />,
      title: "Genuine Spare Parts",
      description:
        "We use only high-quality, genuine spare parts to ensure lasting repairs.",
    },
    {
      id: 4,
      icon: <FaRupeeSign size={40} color="#0078ff" />,
      title: "Affordable Pricing",
      description:
        "Transparent and competitive pricing ensures you get the best value for your money.",
    },
    {
      id: 5,
      icon: <GiLoveSong size={40} color="#0078ff" />,
      title: "100% Satisfaction",
      description:
        "We are committed to delivering exceptional service with customer satisfaction guaranteed.",
    },
  ];

  return (
    <>
      <div id="home" className="brand-container">
        <div className="header-container">
          <img src={apjlogo} alt="APJ" width={200} />
          <div className="header">
            <div className="nav-list">
              <li className="nav-item">
                <a rel="noopener noreferrer" href="#home">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a rel="noopener noreferrer" href="#services">
                  Services
                </a>
              </li>
              <li className="nav-item">
                <a rel="noopener noreferrer" href="#about-us">
                  About us
                </a>
              </li>
              <li className="nav-item">
                <a rel="noopener noreferrer" href="#howitswork">
                  How its work
                </a>
              </li>
            </div>
          </div>
          <a
            rel="noopener noreferrer"
            href="https://wa.me/+917200186306"
            className="contact-button"
          >
            Get in touch
          </a>
        </div>
        <div className="modern-banner">
          <div className="banner-content">
            <div className="text-content">
              <h1 className="brand-heading">APJ ELECTRONICS</h1>
              <h2 className="brand-subheading">
                Don't Let a Broken TV Ruin Your Fun
              </h2>
              <h2 className="brand-subheading">
                Revive Your Entertainment Experience Today!
              </h2>
              <p className="brand-description">
                We Fix All TV Issues Quickly and Reliably!
              </p>
              <a
                rel="noopener noreferrer"
                href="https://wa.me/+917200186306"
                style={{ textDecoration: "none" }}
              >
                <button className="cta-button">Get Your TV Fixed Now</button>
              </a>
            </div>
            <div className="image-content">
              <img
                src={repairmen}
                alt="Repair your TV"
                className="repairmen-img"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="marquee-wrapper">
        <div className="marquee-item">
          <div className="marquee-repeater">
            <p className="focused-text">{text}</p>
          </div>
          <div className="marquee-repeater">
            <p className="focused-text">{text}</p>
          </div>
        </div>
        <div className="marquee-item">
          <div className="marquee-repeater">
            <p className="focused-text">{text}</p>
          </div>
          <div className="marquee-repeater">
            <p className="focused-text">{text}</p>
          </div>
        </div>
      </div>
      <div id="services"></div>
      <br />
      <div className="services">
        <h3 className="service-heading">01 — Our Services</h3>
        <p className="service-para mob-text-res">
          We are a team committed to delivering reliable, efficient, and
          affordable TV repair services for all major brands, including Samsung,
          LG, Sony, Panasonic, Toshiba, TCL, MI, and more. With our expertise,
          we ensure your entertainment never stops.
        </p>
      </div>
      <div className="page-container">
        <div className="card-container">
          {/* Card 1 */}
          <div className="card">
            <div className="card-img-container">
              <img
                src={doorstep}
                alt="Doorstep TV Repair"
                className="card-img"
              />
            </div>
            <div className="card-content">
              <h2 className="card-title">Doorstep TV Repair Service</h2>
              <p className="card-description">
                Expert TV repairs at your doorstep. Our technicians fix issues
                on-site, ensuring your TV is up and running quickly.
              </p>
              <div className="card-tags">
                <span className="card-tag">Doorstep Repair</span>
                <span className="card-tag">On-Site Diagnostics</span>
                <span className="card-tag">Smart TV Services</span>
                <span className="card-tag">Connectivity Fixes</span>
              </div>
            </div>
          </div>

          {/* Card 2 */}
          <div className="card">
            <div className="card-img-container">
              <img
                src={displaysales}
                alt="Display Sales & Installation"
                className="card-img"
              />
            </div>
            <div className="card-content">
              <h2 className="card-title">New Display Sales & Installation</h2>
              <p className="card-description">
                Upgrade your TV with high-quality new displays. We provide
                authentic screens and professional installation.
              </p>
              <div className="card-tags">
                <span className="card-tag">TV Display Replacement</span>
                <span className="card-tag">LED TV Repairs</span>
                <span className="card-tag">OLED Screen Repairs</span>
                <span className="card-tag">High-Quality Parts</span>
              </div>
            </div>
          </div>

          {/* Card 3 */}
          <div className="card">
            <div className="card-img-container">
              <img
                src={hardware}
                alt="Hardware-Level Repairs"
                className="card-img"
              />
            </div>
            <div className="card-content">
              <h2 className="card-title">Affordable Hardware-Level Repairs</h2>
              <p className="card-description">
                From power supply issues to advanced hardware repairs, we offer
                budget-friendly solutions without compromising quality.
              </p>
              <div className="card-tags">
                <span className="card-tag">Hardware Repair</span>
                <span className="card-tag">Power Issue Fixes</span>
                <span className="card-tag">Picture Quality Restoration</span>
                <span className="card-tag">Audio Troubleshooting</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="image-content-layout-container">
        <div className="image-content-layout-image-section">
          <img
            src={repairmachine}
            alt="img"
            className="image-content-layout-image"
          />
        </div>
        <div className="image-content-layout-content-section">
          <h2 className="image-content-layout-title">
            Professional TV Repair Services with Advanced ACF Bonding Technology
          </h2>
          <p className="image-content-layout-text mob-text-res">
            Is your TV screen damaged or showing connectivity issues? Our
            cutting-edge ACF Bonding Machine ensures precise and reliable
            bonding between LCD glass and PCBs, restoring your TV to perfect
            working condition.
            <br />
            <br />
            <span className="machine-top" style={{ fontWeight: "bold" }}>
              Expert TV Repair:
            </span>{" "}
            Fix cracked screens, display issues, and flex cable connections with
            high precision.
            <br />
            <span style={{ fontWeight: "bold" }}>Fast and Reliable:</span>{" "}
            Minimize downtime with our efficient repair process.
            <br />
            <span style={{ fontWeight: "bold" }}>
              Affordable and Durable Solutions:
            </span>{" "}
            Long-lasting repairs that save you money. Let us bring your TV back
            to life!
          </p>
        </div>
      </div>
      <div className="about-us-container">
        <div id="about-us"></div>
        <div className="about-us">
          <h3 className="service-heading">02 — About Us</h3>
          <h3 className="service-para about-us-head" style={{ color: "black" }}>
            We Strive to Deliver Excellent Services
          </h3>
        </div>
        <div
          className="about-us-section"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "20px",
            paddingInline: "45px",
          }}
        >
          <div style={{ flex: "1", marginTop: "30px" }}>
            <img
              src={aboutus}
              alt="About APJ Electronics"
              width="100%"
              style={{
                maxWidth: "500px",
                borderRadius: "20px",
              }}
            />
          </div>
          <div className="about-us-content" style={{ flex: "2" }}>
            <h2
              style={{
                fontSize: "2.5rem",
                fontWeight: "bold",
                color: "#333",
                marginBottom: "20px",
              }}
              className="about-us-heading"
            >
              About APJ Electronics
            </h2>
            <p
              style={{
                fontSize: "1.2rem",
                lineHeight: "1.8",
                color: "#555",
                marginBottom: "20px",
              }}
              className="mob-text-res"
            >
              At APJ, we are committed to providing top-notch TV repair services
              that blend expertise, reliability, and affordability. With years
              of experience in repairing all major brands including Samsung, LG,
              Sony, Panasonic, and more—our certified technicians specialize in
              resolving issues for LED, OLED, Smart TVs, and beyond.
            </p>
            <p
              style={{
                fontSize: "1.2rem",
                lineHeight: "1.8",
                color: "#555",
                marginBottom: "20px",
              }}
              className="mob-text-res"
            >
              We pride ourselves on delivering convenient doorstep services,
              offering genuine parts for long-lasting repairs, and ensuring
              customer satisfaction with every interaction. Whether it’s fixing
              power issues, restoring screen clarity, or resolving connectivity
              problems, we’ve got you covered.
            </p>
            <p
              style={{
                fontSize: "1.2rem",
                lineHeight: "1.8",
                color: "#555",
                marginBottom: "30px",
              }}
              className="mob-text-res"
            >
              Experience quality, trust, and affordability like never before
              with our tailored solutions designed to keep your entertainment
              seamless.
            </p>
            <a
              rel="noopener noreferrer"
              href="#services"
              style={{
                display: "inline-block",
                backgroundColor: "#f3b619",
                color: "#fff",
                padding: "12px 20px",
                fontSize: "1.1rem",
                fontWeight: "600",
                textDecoration: "none",
                borderRadius: "5px",
                transition: "background-color 0.3s",
              }}
              onMouseEnter={(e) => (e.target.style.backgroundColor = "#ff8c00")}
              onMouseLeave={(e) => (e.target.style.backgroundColor = "#f3b619")}
            >
              Explore Our Services
            </a>
          </div>
        </div>
      </div>
      <section
        style={{
          marginTop: "40px",
          padding: "40px 20px",
        }}
      >
        <div style={{ textAlign: "center", marginBottom: "40px" }}>
          <h2
            style={{
              fontSize: "2rem",
              fontWeight: "bold",
              color: "#333",
              marginBottom: "10px",
            }}
          >
            Explore our range of display sizes, tailored for every need.
          </h2>
          <p style={{ fontSize: "1.1rem", color: "#666" }}></p>
        </div>
        <div className="repair-process">
          <div className="card-inch">
            <img
              src={threetwoinch}
              width={260}
              alt="Reach Out"
              className="card-icon"
            />

            <h3>32-Inch Displays</h3>
            <p>
              Compact and efficient for small spaces, offering excellent
              clarity.
            </p>
          </div>
          <div className="card-inch">
            <img
              src={fourzeroinch}
              width={280}
              alt="Free Estimate"
              className="card-icon"
            />
            <h3>40-Inch Displays</h3>
            <p>Perfect for moderate-sized rooms with enhanced visuals.</p>
          </div>
          <div className="card-inch">
            <img
              src={fourthreeinch}
              width={265}
              alt="Schedule Repair"
              className="card-icon"
            />
            <h3>43-Inch Displays</h3>
            <p>Bring home the cinema experience with vibrant image quality.</p>
          </div>
          <div className="card-inch">
            <img
              src={fivefiveinch}
              width={280}
              alt="Repair & Testing"
              className="card-icon"
            />
            <h3>55-Inch Displays</h3>
            <p>An ideal choice for larger spaces with immersive visuals.</p>
          </div>
        </div>
      </section>
      <div id="howitswork"></div>
      <br />
      <br />
      <br />
      <h2 className="section-heading">How Our TV Repair Process Works</h2>
      <p className="section-description">
        We make repairing your TV simple and hassle-free. Follow these four easy
        steps to get your TV back in working order!
      </p>
      <div className="repair-process">
        <div className="card-repair step-1">
          <h3>Step 1: Reach Out</h3>
          <p>
            Contact us via phone, email, or our website, describing the issue
            with your TV.
          </p>
        </div>
        <div className="card-repair step-2">
          <h3>Step 2: Get Estimate</h3>
          <p>
            We will provide a no-obligation, get estimate based on the issue and
            your TV model.
          </p>
        </div>
        <div className="card-repair step-3">
          <h3>Step 3: Schedule a Repair</h3>
          <p>
            Choose a convenient time for our technicians to visit your home or
            arrange for a pick-up service.
          </p>
        </div>
        <div className="card-repair step-4">
          <h3>Step 4: Repair & Testing</h3>
          <p>
            We will repair your TV and perform thorough testing to ensure it is
            functioning properly before returning it to you.
          </p>
        </div>
      </div>
      <div className="contact-float">
        <div className="contact-options">
          <a
            rel="noopener noreferrer"
            href="https://wa.me/+917200186306"
            className="contact-option"
          >
            <img src={whatsapplogo} alt="WhatsApp" />
          </a>
          <a
            rel="noopener noreferrer"
            href="tel:+91 7200186306"
            className="contact-option"
          >
            <img src={call} alt="Call" />
          </a>
          <a
            rel="noopener noreferrer"
            href="mailto:apjenterprises2023@gmail.com"
            className="contact-option"
          >
            <img src={email} alt="Email" />
          </a>
        </div>
        <div className="main-icon">
          <img src={contactus} alt="Contact Us" />
        </div>
      </div>
      <div className="reviews-container">
        <h2 className="reviews-title">What Our Customers Say</h2>
        <div className="reviews-wrapper" ref={scrollRef}>
          {reviews.map((review) => (
            <div className="review-card" key={review.id}>
              <div className="review-header">
                <div className="avatar">{review.name.charAt(0)}</div>
                <h3 className="review-name">{review.name}</h3>
              </div>
              <p className="review-text">{review.review}</p>
            </div>
          ))}
        </div>
      </div>
      <section className="why">
        <h2 className="why-choose-us">Why Choose Us</h2>
        <div className="feature-grid">
          {features.map((feature) => (
            <div key={feature.id} className="feature-item">
              <div className="icon-container">{feature.icon}</div>
              <div>
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
      </section>
      <div className="faq-container">
        <h2 className="faq-title">Frequently Asked Questions</h2>
        <div className="faq-list">
          {faqData.map((faq, index) => (
            <div
              className={`faq-item ${activeIndex === index ? "active" : ""}`}
              key={index}
            >
              <div
                className="faq-question"
                onClick={() => toggleAccordion(index)}
              >
                {faq.question}
                <span className="faq-icon">
                  {activeIndex === index ? "-" : "+"}
                </span>
              </div>
              <div
                className="faq-answer"
                style={{ maxHeight: activeIndex === index ? "300px" : "0" }}
              >
                <p>{faq.answer}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <footer className="footer">
        <div className="footer-container">
          <div className="footer-about">
            <h2>APJ Electronics</h2>
            <p>
              Dedicated to providing expert, reliable, and affordable TV repair
              services for all major brands.
            </p>
            <p>Door No. 3, Ganesh Nagar, Iyyencherry, Urapakkam</p>
          </div>
          <div className="footer-links">
            <h3>Quick Links</h3>
            <ul>
              <li>
                <a rel="noopener noreferrer" href="#about-us">
                  About Us
                </a>
              </li>
              <li>
                <a rel="noopener noreferrer" href="#services">
                  Services
                </a>
              </li>
              <li>
                <a rel="noopener noreferrer" href="#howitswork">
                  How its work
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-contact">
            <h3>Contact Us</h3>
            <p>
              Phone:{" "}
              <a
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "white" }}
                href="tel:+919876543210"
              >
                +917200186306
              </a>
            </p>
            <p>
              Email:{" "}
              <a
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "white" }}
                href="mailto:apjenterprises2023@gmail.com"
              >
                apjenterprises2023@gmail.com
              </a>
            </p>
          </div>
        </div>
        <hr className="footer-divider" />
        <p className="footer-copyright">
          &copy; {new Date().getFullYear()} APJ Electronics. All Rights
          Reserved.
        </p>
      </footer>
    </>
  );
};

export default Home;
